<template>
    <div>
        <transportations-component/>
        <footer-component/>
    </div>
</template>

<script>
    import TransportationsComponent from "@/components/transports/TransportationsComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "Transportations",
        title: "Transportes | Turismo BC",
        components: {FooterComponent, TransportationsComponent}
    }
</script>

<style scoped>

</style>